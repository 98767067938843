import React from 'react';
import { Link } from 'react-router-dom';
import Article from './Article'; // Import the Article component
import styles from './News.module.css'; // Import CSS module

const News = () => {
  return (
    <>
    <h1 className={styles.headline}>Neuigkeiten</h1>
        <ul className={styles.navList}>
          <li>
            <Link to="/" className={styles.navLink}>Home</Link>
          </li>
          <li>
            <Link to="/news" className={`${styles.active} ${styles.navLink}`}>Neuigkeiten</Link>
            </li>
          <li>
            <Link to="/gallery" className={styles.navLink}>Galerie</Link>
          </li>
          <li>
            <Link to="/contact" className={styles.navLink}>Kontakt</Link>
          </li>
        </ul>    
    <div className={styles.news}>
        <Article
            thumbnail="https://i.ytimg.com/vi/YFyMdfWyGRs/maxresdefault.jpg"
            text="Doku Veľká Fatra - Naturjuwel in der Slowakei: Die Arbeiten zu diesem Film haben fast ein Jahr in Anspruch genommen. Von der Recherche, über die Planung, Dreharbeiten vor Ort und die Postproduktion. Die Veľká Fatra bietet einen Artenreichtum in Flora und Fauna, der in Europa seinesgleichen sucht..."
            link="https://www.youtube.com/watch?v=YFyMdfWyGRs"
        />
        <Article
            thumbnail="./403987537_1300312444697074_2503370548261994475_n.jpg"
            text="Stolzer Gewinner der Medaille 'UNION INTERNATIONALE DU CINEMA' (UNICA) mit Diplom auf dem internationalen Filmfestival 'Tatranský Kamzík 2023' in der Slowakei für unseren Film 'Pol'ana'!"
            link="https://www.instagram.com/p/C0HyZMatiMX/?img_index=1"
        />
        <Article
            thumbnail="./366415063_1456836431804785_6827924925741697293_n.jpg"
            text="UCM wird ab sofort auf Instagram begleitet"
            link="https://www.instagram.com/p/CvztlKdoolo/"
        />
    </div>
    </>
  );
};

export default News;
