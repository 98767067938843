import React from 'react';
import { Link } from 'react-router-dom';
import Card from './Card'; // Import the Card component
import styles from './Gallery.module.css'; // Import the CSS module

const Gallery = () => {
  return (
    <>
    <h1 className={styles.headline}>Galerie</h1>
        <ul className={styles.navList}>
        <li>
            <Link to="/" className={styles.navLink}>Home</Link>
          </li>
          <li>
            <Link to="/news" className={styles.navLink}>Neuigkeiten</Link>
            </li>
          <li>
            <Link to="/gallery" className={`${styles.active} ${styles.navLink}`}>Galerie</Link>
          </li>
          <li>
            <Link to="/contact" className={styles.navLink}>Kontakt</Link>
          </li>
        </ul>

    <div className={styles.gallery}>
      <Card
        image="https://i.ytimg.com/vi/YFyMdfWyGRs/maxresdefault.jpg"
        text="Doku Veľká Fatra - Naturjuwel in der Slowakei (slovenske titulki)"
        link="https://www.youtube.com/watch?v=YFyMdfWyGRs"
      />
      <Card
        image="https://i.ytimg.com/vi/ERekWF_WIKU/maxresdefault.jpg"
        text="Die Teufelskralle - Kurzportrait"
        link="https://www.youtube.com/watch?v=ERekWF_WIKU"
      />
      <Card
        image="https://i.ytimg.com/vi/4-cldbziWdY/maxresdefault.jpg"
        text="Preisverleihung auf Internationalem Filmfest Tatranský Kamzík Liptovsky Jan"
        link="https://www.youtube.com/watch?v=4-cldbziWdY"
      />
      <Card
        image="https://i.ytimg.com/vi/KtLcoo9g4ig/hqdefault.jpg"
        text="Pol'ana: Unbekannte Wildnis im Herzen der Slowakei"
        link="https://www.youtube.com/watch?v=KtLcoo9g4ig&t=55s"
      />
      <Card
        image="https://i.ytimg.com/vi/IRkpZFXS5EY/maxresdefault.jpg"
        text="Hron 3.Teil - Natur- und Vogelparadies Donauniederung - Flußwanderung Slowakei"
        link="https://www.youtube.com/watch?v=IRkpZFXS5EY"
      />
      <Card
        image="https://i.ytimg.com/vi/8854zNlMqlM/maxresdefault.jpg"
        text="Der Hron - Teil 2: Pohronie - Eine Flußlandschaft in der Slowakei"
        link="https://www.youtube.com/watch?v=8854zNlMqlM"
      />
      <Card
        image="https://i.ytimg.com/vi/wMRo2K1IV2I/maxresdefault.jpg"
        text="Eine Flußlandschaft in der Slowakei - Von der Quelle zur Mündung - Teil 1 - Horehronie"
        link="https://www.youtube.com/watch?v=wMRo2K1IV2I"
      />
      <Card
        image="https://i.ytimg.com/vi/HrdLx_SWI9U/hqdefault.jpg"
        text="Muránska Planina - Herbstwanderung (Jesenná túra) - Slowakei - Karpaten"
        link="https://www.youtube.com/watch?v=HrdLx_SWI9U"
      />
      <Card
        image="https://i.ytimg.com/vi/eelILx150Q4/maxresdefault.jpg"
        text="Nationalpark Poloniny - Die letzten Urwälder Europas - Slowakei Karpaten"
        link="https://www.youtube.com/watch?v=eelILx150Q4"
      />
      <Card
        image="https://i.ytimg.com/vi/aj0HrxdTBUI/maxresdefault.jpg"
        text="Kraniche und Schwarzstörche im Erzgebirge - Eine Frühlingswanderung - Wildvögel / Zugvögel"
        link="https://www.youtube.com/watch?v=aj0HrxdTBUI"
      />
      <Card
        image="https://i.ytimg.com/vi/M8X_Fb7Vnak/hqdefault.jpg"
        text="Wird es Frühling? Heimische Vögel - Erzgebirge Buntspecht Rotkehlchen Amsel Buchfink Heckenbraunelle"
        link="https://www.youtube.com/watch?v=M8X_Fb7Vnak"
      />
      <Card
        image="https://i.ytimg.com/vi/akuC4QHTMP0/hqdefault.jpg"
        text="Wintertraum im Erzgebirge - cinematic colours and lights"
        link="https://www.youtube.com/watch?v=akuC4QHTMP0"
      />
      <Card
        image="https://i.ytimg.com/vi/oO81Gjqlq-Y/hqdefault.jpg"
        text="Naturparadies Muránska planina - Karpaten - Wilde Pferde, Reiten, Ziesel, Schafe -Slowakei/Slovensko"
        link="https://www.youtube.com/watch?v=oO81Gjqlq-Y"
      />
      <Card
        image="https://i.ytimg.com/vi/223Nya-7yJA/hqdefault.jpg"
        text="HORÚCA NOVINKA V ŠUMIACI - MAŠKRTKY OD KATKY - ŠUMIAC Horehronie"
        link="https://www.youtube.com/watch?v=223Nya-7yJA"
      />
      <Card
        image="https://i.ytimg.com/vi/J4ruRZQ568Y/maxresdefault.jpg"
        text="Wild- und Singvögel im Garten / Handaufzucht Buchfink / Balz / Vogelhochzeit / Dokumentation 4K"
        link="https://www.youtube.com/watch?v=J4ruRZQ568Y"
      />
      <Card
        image="https://i.ytimg.com/vi/_VWW0in1lbU/maxresdefault.jpg"
        text="Zimné kúzlo - Winterzauber - Muránska Planina + Horehronie, Šumiac a okolie Slowakei / Karpaten"
        link="https://www.youtube.com/watch?v=_VWW0in1lbU"
      />
      <Card
        image="https://i.ytimg.com/vi/FrN-EmBnEiM/maxresdefault.jpg"
        text="Jesenné dojmy - Herbstimpressionen - Muránska Planina + Horehronie - Slowakei/Slovakia/Slovensko"
        link="https://www.youtube.com/watch?v=FrN-EmBnEiM"
      />
      <Card
        image="https://i.ytimg.com/vi/KMKePxfyVYU/maxresdefault.jpg"
        text="Mala Fatra / Terchova / Janošikove diery / Karpaten / Slowakei/Slovakia/Slovensko"
        link="https://www.youtube.com/watch?v=KMKePxfyVYU&t=1694s"
      />
    </div>
    </>
  );
};

export default Gallery;
